import { Card, FormControlLabel, Grid, Radio, Typography } from "@mui/material";
import { useEffect, useReducer, useState, } from "react";
import Form, { FormActions } from "./Form";
import FormFooter from "./FormFooter";
import moment from "moment";
import { REPORT_APIS, } from "../common/apis";
import useFetch from "../hooks/useFetch";

const SubscriptionReports = () => {

    const { postFile } = useFetch();

    const [data, dispatch] = useReducer(SearchFilterReducer, defaultProducts);
    const [localData, setLocalData] = useState({});

    const handleRadioChange = (e) => {
        let val = Number(e.target.value);
        dispatch({
            type: FormActions.INPUT_CHANGED,
            payload: { name: "mode", value: val },
        });
    };

    const handleExport = async () => {
        data?.mode == 0
            ? data?.Format == 0 ? formClicks?.getHistoryXLSX() : formClicks?.getHistoryXML()
            : formClicks?.getPeriodic();
    }

    const formClicks = {
        getHistoryXLSX: async function () {
            await postFile(`${REPORT_APIS.HISTORY_XLSX}`, {
                Date1: data?.Date1,
                Date2: data?.Date2,
                ProductId: localData?.CustomerId > 0 ? null : localData?.ProductId,
                CustomerId: (localData?.SiteId || localData?.SiteID) > 0 ? null : localData?.CustomerId,
                SiteId: localData?.UnitId > 0 ? null : localData?.SiteId || localData?.SiteID,
                UnitId: localData?.ComponentId > 0 ? null : localData?.UnitId,
                ComponentId: localData?.ComponentId,
                ContactId: localData?.ContactId,
            }, "Komatsu CMS Reference");
        },
        getHistoryXML: async function () {
            await postFile(`${REPORT_APIS.HISTORY_XML}`, {
                Date1: data?.Date1,
                Date2: data?.Date2,
                ProductId: localData?.CustomerId > 0 ? null : localData?.ProductId,
                CustomerId: (localData?.SiteId || localData?.SiteID) > 0 ? null : localData?.CustomerId,
                SiteId: localData?.UnitId > 0 ? null : localData?.SiteId || localData?.SiteID,
                UnitId: localData?.ComponentId > 0 ? null : localData?.UnitId,
                ComponentId: localData?.ComponentId,
                ContactId: localData?.ContactId,
            }, "Komatsu CMS Reference");
        },
        getPeriodic: async function () {
            await postFile(`${REPORT_APIS.VIEW_SUBSCRIPTION_REPORT}`, {
                Date1: data?.Date1,
                ParentId: localData?.ParentId,
                CustomerId: (localData?.SiteId || localData?.SiteID) > 0 ? null : localData?.CustomerId,
                SiteId: localData?.UnitId > 0 ? null : localData?.SiteId || localData?.SiteID,
                UnitId: localData?.ComponentId > 0 ? null : localData?.UnitId,
                Monthly: data?.Period == 0 ? true : false,
                ReportId: data?.Type,//12-13,
            }, "Komatsu CMS Reference");
        },
    };

    useEffect(() => {
        document.title = "Component History and Subscription Reports";
        const lData = JSON.parse(localStorage.getItem("reports-by-date"));
        setLocalData(lData);
    }, []);

    return (
        <Card sx={{ p: 1 }}>
            <Grid container spacing={1}>
                <Grid item xs={12}  >
                    <Typography >
                        Select the report for the product.
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Form
                        sections={GetFormSections(data, handleRadioChange)}
                        data={data}
                        dispatch={dispatch}
                    />
                </Grid>

                <Grid item xs={12}>
                    <FormFooter
                        buttons={GetFormButtons(handleExport)}
                    />
                </Grid>
            </Grid>
        </ Card>
    );
}

export default SubscriptionReports;

function GetFormSections(data, handleRadioChange) {
    return [
        {
            fields: [
                {
                    name: "Date1",
                    label: "From",
                    type: "date",
                    xsCol: 6,
                },
                {
                    name: "Date2",
                    label: "To",
                    type: "date",
                    xsCol: 6,
                    disabled: data?.mode === 1,
                },
                {
                    group: [
                        {
                            name: "mode",
                            xsCol: 3,
                            component: function () {
                                return (
                                    <FormControlLabel
                                        checked={data?.mode === 0}
                                        onChange={handleRadioChange}
                                        value={0}
                                        control={<Radio />}
                                        label="History"
                                    />
                                );
                            },
                        },
                        // {
                        //     name: "History",
                        //     label: "History",
                        //     type: "switch",
                        //     xsCol: 3,
                        // },
                        {
                            name: "Format",
                            label: "Format",
                            type: "dropdown",
                            disabled: data?.mode === 1,
                            xsCol: 4,
                            required: true,
                            selectOptions: [
                                { Id: 0, value: 0, text: "XLSX" },
                                { Id: 1, value: 1, text: "XML" }
                            ],
                        },

                    ],
                },
                {
                    name: "mode",
                    xsCol: 3,
                    component: function () {
                        return (
                            <FormControlLabel
                                checked={data?.mode === 1}
                                onChange={handleRadioChange}
                                value={1}
                                control={<Radio />}
                                label="Periodic"
                            />
                        );
                    },
                },
                // {
                //     name: "periodic",
                //     label: "periodic",
                //     type: "switch",
                //     xsCol: 3,
                // },
                {
                    name: "Period",
                    label: "Period",
                    type: "dropdown",
                    xsCol: 4,
                    disabled: data?.mode === 0,
                    required: true,
                    selectOptions: [
                        { Id: 0, value: 0, text: "Monthly" },
                        { Id: 1, value: 1, text: "Weekly" }
                    ],
                },
                {
                    name: "Type",
                    label: "Type",
                    type: "dropdown",
                    xsCol: 4,
                    disabled: data?.mode === 0,
                    required: true,
                    selectOptions: [
                        { Id: 12, value: 12, text: "Summary Report" },
                        { Id: 13, value: 13, text: "Detail Report" }
                    ],
                },
            ],
        },
    ];
}

const defaultProducts = {
    Date1: new moment().subtract(1, 'y'),
    Date2: new moment(),
    mode: 0,
    Format: 0,
    Period: 0,
    Type: 12,
};

const EventActions = {
    LOAD_DATA: "load-data",
};

function SearchFilterReducer(state, action) {
    if (action.type === FormActions.INPUT_CHANGED) {
        return { ...state, [action.payload.name]: action.payload.value };
    }
    if (action.type === FormActions.RESET_FORM) {
        return { ...action.payload };
    }
    if (action.type === EventActions.LOAD_DATA) {
        return { ...action.payload };
    }
    return state;
}

const GetFormButtons = (handleExport) => [
    {
        label: "Export",
        onClick: handleExport,
    },
];