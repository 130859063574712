import NewDataTable, { NewDataGridAction } from "../../../components/NewDataTable";
import renderCellExpand from "../../../components/DatatableCellExpand";
import { useWindowPopups } from "../../../components/useWindowPopups";
import { useEffect, useState } from "react";

export default function SampleReportsOverviewFormTable({
  data,
  rowSelected,
  setOpenDialog,
  ...props
}) {

  const [columnVisibility, setColumnVisibility] = useState({ Customer: false, Site: false, Unit: false, Component: false, Primary: false });

  useEffect(() => {
    if (data) {
      let columnsToDisplay = { ...columnVisibility };
      if (data?.filter(i => i?.LevelText === 'Customer')?.length > 0) {
        columnsToDisplay = { ...columnsToDisplay, Customer: true };
      }
      if (data?.filter(i => i?.LevelText === 'Site')?.length > 0) {
        columnsToDisplay = { ...columnsToDisplay, Customer: true, Site: true };
      }
      if (data?.filter(i => i?.LevelText === 'Unit')?.length > 0) {
        columnsToDisplay = { ...columnsToDisplay, Customer: true, Site: true, Unit: true };
      }
      if (data?.filter(i => i?.LevelText === 'Component')?.length > 0) {
        columnsToDisplay = { ...columnsToDisplay, Customer: true, Site: true, Unit: true, Component: true };
      }
      if (data?.filter(i => i?.Primary !== null)?.length > 0) {
        columnsToDisplay = { ...columnsToDisplay, Primary: true, };
      }
      setColumnVisibility(columnsToDisplay)
    }
  }, [data])

  const options = {
    ...props,
    columns: GetColumns(
      editClick,
      accessClick,
      reportsClick,
      subscriptionsClick,
      primaryClick
    ),
    dataSource: (data || []).map((x, i) => ({
      ...x, keyProp: i
    })),

    sx: { height: "70dvh" },
    getRowId: (originalRow) => (originalRow?.keyProp || ""),

    enableColumnActions: false,
    cellStylingHandler: "contactReportsPermission",

    state: { columnVisibility }, //manage columnVisibility state
    onColumnVisibilityChange: setColumnVisibility,

    muiTableBodyProps: {
      sx: (theme) => ({
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(odd):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "inherit" },
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]) > td':
          { backgroundColor: "#e2f2ff" },
        // backgroundColor: "inherit",//
        '& tr:nth-of-type(even):not([data-selected="true"]):not([data-pinned="true"]):hover > td':
          { backgroundColor: "#e2f2ff" },
      }),
    },
    muiTableBodyCellProps: ({ cell, column, row, table }) => {
      return {
        sx: cell.column.columnDef.accessorKey === "Primary" ? {
          backgroundColor: (row?.original?.Primary !== null ? "green !important" : ""),
          color: (row?.original?.Primary !== null ? "white !important" : ""),
          py: 0,
          borderLeft: "1px solid #d0d0d0",
          height: "22px",
        } : cell.column.columnDef.accessorKey === "EmailStatus" ? {
          backgroundColor: (row?.original?.EmailColour + "!important") || "",
          color: "white",
          borderLeft: "0.5px solid #d0d0d0",
          py: 0,
        } : cell.column.columnDef.accessorKey === "SampleReportStatus" ? {
          backgroundColor: (row?.original?.SampleReportColour + "!important") || "",
          color: "white",
          borderLeft: "0.5px solid #d0d0d0",
          py: 0,
        } : cell.column.columnDef.accessorKey === "ExcelStatus" ? {
          backgroundColor: (row?.original?.ExcelColour + "!important") || "",
          color: "white",
          borderLeft: "0.5px solid #d0d0d0",
          py: 0,
        } : cell.column.columnDef.accessorKey === "MachineReportStatus" ? {
          backgroundColor: (row?.original?.MachineReportColour + "!important") || "",
          color: "white",
          borderLeft: "0.5px solid #d0d0d0",
          py: 0,
        } : cell.column.columnDef.accessorKey === "SmsStatus" ? {
          backgroundColor: (row?.original?.SmsColour + "!important") || "",
          color: "white",
          borderLeft: "0.5px solid #d0d0d0",
          py: 0,
        } : cell.column.columnDef.accessorKey === "XmlStatus" ? {
          backgroundColor: (row?.original?.XmlColour + "!important") || "",
          color: "white",
          borderLeft: "0.5px solid #d0d0d0",
          py: 0,
        } : cell.column.columnDef.accessorKey === "MailStatus" ? {
          backgroundColor: (row?.original?.MailColour + "!important") || "",
          color: "white",
          borderLeft: "0.5px solid #d0d0d0",
          py: 0,
        } : cell.column.columnDef.accessorKey === "Source" ? {
          backgroundColor: (
            row?.original?.IsKomatsu
              ? "#140a9a !important"
              : row?.original?.IsPortalUser
                ? "#ff4500 !important"
                : row?.original?.IsLabcheckUser
                  ? "#228b22 !important"
                  : ""
          ),
          color: "white",
          border: "0.5px solid #d0d0d0",
          py: 0,
          height: "22px",
        } : {
          py: 0, height: "22px", borderLeft: "1px solid #d0d0d0"
        }
      }
    },
    muiTableBodyRowProps: ({ cell, column, row, table }) => {
      return {
        sx: { height: "22px", py: 0, borderLeft: "1px solid #d0d0d0" }
      }
    },
  };

  return (
    <>
      <NewDataTable {...options} />
    </>
  );


  function HandleNewWindow(actionName, data) {
    useWindowPopups(actionName, data)
  }

  function editClick(row) {
    HandleNewWindow("edit-contact", row)
  }

  function accessClick(row) {
    HandleNewWindow("contact-access-permission", {
      ContactId: row?.ContactId,
      PropId: 2,
      UserName: row?.Username,
      DisplayName: row?.DisplayName,
      IsKomatsu: row?.IsKomatsu,
    });
  }
  function reportsClick(row) {
    HandleNewWindow("contact-access-permission", {
      ContactId: row?.ContactId,
      PropId: 3,
      UserName: row?.Username,
      DisplayName: row?.DisplayName,
      IsKomatsu: row?.IsKomatsu,
    });
  }
  function subscriptionsClick(row) {
    HandleNewWindow("contact-access-permission", {
      ContactId: row?.ContactId,
      PropId: 4,
      UserName: row?.Username,
      DisplayName: row?.DisplayName,
      IsKomatsu: row?.IsKomatsu,
    });
  }
  function primaryClick(row) {
    HandleNewWindow("contact-primary-mapping", {
      ContactId: row?.ContactId,
      PropId: 6,
      UserName: row?.Username,
      DisplayName: row?.DisplayName,
    });
  }
}

const GetColumns = (
  editClick,
  accessClick,
  reportsClick,
  subscriptionsClick,
  primaryClick
) => [
    {
      header: "Edit",
      size: 40,
      Cell: function ({ row }) {
        return (
          <>
            <NewDataGridAction
              minWidth={"40px"}
              row={row}
              onClick={() => editClick(row?.original)}
              label="Edit"
            />
          </>
        );
      },
    },
    {
      header: "Access",
      size: 45,
      Cell: function ({ row }) {
        return (
          <>
            <NewDataGridAction
              minWidth={"45px"}
              row={row}
              onClick={() => accessClick(row?.original)}
              label="Access"
            />
          </>
        );
      },
    },
    {
      header: "Reports",
      size: 45,
      Cell: function ({ row }) {
        return (
          <>
            <NewDataGridAction
              minWidth={"45px"}
              row={row}
              onClick={() => reportsClick(row?.original)}
              label="Reports"
            />
          </>
        );
      },
    },
    {
      header: "Subscriptions",
      size: 75,
      Cell: function ({ row }) {
        return (
          <>
            <NewDataGridAction
              minWidth={"75px"}
              row={row}
              onClick={() => subscriptionsClick(row?.original)}
              label="Subscriptions"
            />
          </>
        );
      },
    },
    {
      header: "Primary.",
      size: 50,
      Cell: function ({ row }) {
        return (
          <>
            <NewDataGridAction
              minWidth={"50px"}
              row={row}
              onClick={() => primaryClick(row?.original)}
              label="Primary"
            />
          </>
        );
      },
    },
    {
      header: "Email",
      accessorKey: "Username",
      size: 150,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Name",
      accessorKey: "DisplayName",
      size: 95,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Primary",
      accessorKey: "Primary",
      size: 50,
      enableSorting: false,
    },
    {
      header: "Scope",
      accessorKey: "LevelText",
      size: 60,
      enableSorting: false,
    },
    {
      header: "Email",
      accessorKey: "EmailStatus",
      size: 40,
      enableSorting: false,
    },
    {
      header: "Sample",
      accessorKey: "SampleReportStatus",
      size: 45,
      enableSorting: false,
    },
    {
      header: "Excel",
      accessorKey: "ExcelStatus",
      size: 40,
      enableSorting: false,
    },
    {
      header: "Machine",
      accessorKey: "MachineReportStatus",
      size: 47,
      enableSorting: false,
    },
    {
      header: "SMS",
      accessorKey: "SmsStatus",
      size: 35,
      enableSorting: false,
    },

    {
      header: "XML",
      accessorKey: "XmlStatus",
      size: 35,
      enableSorting: false,
    },

    {
      header: "Mail",
      accessorKey: "MailStatus",
      size: 35,
      enableSorting: false,
    },
    {
      header: "Customer",
      accessorKey: "Customer",
      size: 110,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Site",
      accessorKey: "Site",
      size: 110,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Unit",
      accessorKey: "Unit",
      size: 110,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Component",
      accessorKey: "Component",
      size: 110,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
    {
      header: "Source",
      accessorKey: "Source",
      size: 62,
    },

    {
      header: "Mobile",
      accessorKey: "Mobile",
      size: 75,
      Cell: ({ renderedCellValue }) => <pre style={{ paddingTop: 0.25, fontSize: "11px" }}>{(renderedCellValue)}</pre>,
    },
    {
      header: "Telephone",
      accessorKey: "Telephone",
      size: 75,
      Cell: ({ renderedCellValue }) => <pre style={{ paddingTop: 0.25, fontSize: "11px" }}>{(renderedCellValue)}</pre>,
    },
    {
      header: "Role",
      accessorKey: "ContactRole",
      size: 50,
      enableSorting: false,
    },
    {
      header: "Archived",
      accessorKey: "Archived",
      size: 50,
      enableSorting: false,
    },
    {
      header: "Company",
      accessorKey: "Company",
      size: 120,
      Cell: ({ renderedCellValue }) => renderCellExpand({ value: renderedCellValue }),
    },
  ];
