import { Box, Button, Card, Grid, Typography } from "@mui/material";
import { AddAPhoto, } from "@mui/icons-material";
import { useCallback, useEffect, useReducer, useState } from "react";
import Form, { FormActions } from "../components/Form";
import FormFooter from "../components/FormFooter";
import SampleReducer, { SampleActions } from "./reducers/SampleReducer";
import useFetch from "../hooks/useFetch";
import AppDialog from "../components/AppDialog";
import ImagePick from "../components/SelectImage";
import DropDownAdvance from "../components/DropDownAdvance";
import GetStepNames from "../common/GetStepNames";
import { DETAILS_APIS } from "../common/apis";
import { useRoot, } from "../rootContext";
import ManageSample from "../features/quick-details/manage/manage-sample/ManageSample";
import ConfirmModal from "../components/ConfirmModal";
import { TRAY_APIS } from "../common/apis";
import { toLocalDate2 } from "../utils";
import EditTray from "./EditTray";
import NewFluid from "../features/master-data/fluids/NewFluid";
import NewVersion from "../features/trays/05-evaluation/NewVersion";
import { useLocation } from "react-router-dom";
import useMasterData from "../hooks/useMasterData";

export default function EditSample({ cancel, sampleNo, setOpenEditSample, reLoadViewHandler, filterData }) {

  const { CanDoDataMaintenance, CanLaboratoryTrays, CanEvaluateNotTrainee, CanEvaluate, CanAdministrate } = useRoot();
  const [data, dispatch] = useReducer(SampleReducer, {});
  const { get, post } = useFetch();
  let location = useLocation();
  const { getMasterData } = useMasterData();

  const [dropdownData, setDropdownData] = useState([]);
  const [imagePickerOpen, setImagePickerOpen] = useState(false);
  const [openManagement, setOpenManagement] = useState(false);
  const [openTray, setOpenTray] = useState(false);
  const [openFluid, setOpenFluid] = useState(false);
  const [openVersion, setOpenVersion] = useState(false);
  const [openAlert, setOpenAlert] = useState({ open: false, title: "", });
  const [localSample, setLocalSample] = useState(0);
  const [localData, setLocalData] = useState({});
  const [histCheck, setHistCheck] = useState(false);

  const actions = {
    save: async function (e, option) {
      //{
      localStorage.removeItem("edit-sample-resp");

      const req = {
        Registration: {
          ...data,
          IsInternalOnly: data.Internal,
          FluidChangedAt: data.FluidChangedHours,
          FluidChangedAtDate: data.FluidChangedDate,
          //  CompChangedEventId: data.ComponentChanged,
          ComponentChangedEventId: data.ComponentChangedEventId,
          ComponentChangedAt: data.CompChangedHours,
          ComponentChangedAtDate: data.CompChangedDate,
          ComponentRepaired: data.ComponentRepaired,
          ComponentRepairedAt: data.CompRepairedHours,
          ComponentRepairedAtDate: data.CompRepairedDate,
          MeterChanged: data.MeterChanged,
          MeterChangedAt: data.MeterChangedHours,
          MeterChangedAtDate: data.MeterChangedDate,
          MeterReading: data?.MeterReading === "" ? null : data?.MeterReading >= 0 ? data?.MeterReading : null,
          ComponentReading: data?.ComponentReading === "" ? null : data?.ComponentReading >= 0 ? data?.ComponentReading : null,
          FluidHours: data?.FluidHours === "" ? null : data?.FluidHours >= 0 ? data?.FluidHours : null,

          Screen: "Sample",
        },
        SampleNotes: data.SampleNotes || "",
        CustomerNote: data.CustomerComments || "",
        Delivered: data.Delivered,
        UnitId: data.UnitId ? data.UnitId : null,
      };
      const responseEditSample = await post(DETAILS_APIS.EDIT_SAMPLE, req);
      if (responseEditSample?.EventLogId >= 0) {
        reLoadViewHandler && reLoadViewHandler()
      }
      setOpenEditSample && setOpenEditSample(false);
      cancel && cancel();

      if (location.pathname === "/trays/edit-sample") {
        localStorage.removeItem("edit-sample");
        const treeData = { ProductId: data?.ProductId || localData?.ProductId, StartLetter: localData?.StartLetter, CustomerId: data?.CustomerId || localData?.CustomerId, SiteId: data?.SiteId, UnitId: data?.UnitId, ComponentId: data?.ComponentId, refreshCheck: true }

        localStorage.setItem("edit-sample-resp", JSON.stringify({ ...localData, SampleNumber: data?.SampleNumber, historySample: histCheck, }));
        localStorage.setItem("tree-resp", JSON.stringify({ ...treeData }));
        window.dispatchEvent(new Event('storage'));
        window.close();
      }
    },
    imageOpen: function (e) {
      setImagePickerOpen(true);
    },
    trayClick: function (e) {
      setOpenTray(true);
    },
    fluidClick: function (e) {
      setOpenFluid(true);
    },
    versionClick: function (e) {
      setOpenVersion(true);
    },
    manage: async function () {
      setOpenManagement(true);
    },
    resendSample: async () => setOpenAlert({
      open: true, title: "Sample Re-Approval",
      message: `Do you wish to resend sample ${sampleNo || localSample} to the Approval Screen?`,
      handleFunction: () => { setOpenAlert({ open: false }); handleReapproveSample(); },
    }),
    resendTray: async () => setOpenAlert({
      open: true, title: "Tray Re-Approval",
      message: `Do you wish to resend the already approved samples for ${data?.Laboratory} for ${toLocalDate2(data?.Registered)} in tray ${data?.Tray} to the Approval Screen?`,
      handleFunction: () => { setOpenAlert({ open: false }); handleReapproveTray(); },
    }),
    deleteSample: async () => setOpenAlert({
      open: true, title: "Delete Sample",
      message: `Are you sure you wish to Delete this sample?
      If an old version exists for this sample, it will be reactivated`,
      handleFunction: () => {
        setOpenAlert({ open: false }); handleDeleteSample();
      },
    }),
    cancel: cancel,
  };

  const handleDeleteSample = async () => {
    const sampleDeleted = await get(`${TRAY_APIS.DELETE_SELECTED_SAMPLE}?SampleNumber=${sampleNo || localSample}&Screen=Edit Sample`);
    if (sampleDeleted > 0) {
      reLoadViewHandler && reLoadViewHandler(filterData)
      cancel && cancel()
      if (location.pathname === "/trays/edit-sample") {
        localStorage.removeItem("edit-sample");
        window.close()
      }
    }
  }

  const handleReapproveSample = async () => {
    await get(`${TRAY_APIS.REAPPROVE_SAMPLE}?SampleNumber=${sampleNo || localSample}&Screen=Edit Sample`);
  }

  const handleReapproveTray = async () => {
    await get(`${TRAY_APIS.REAPPROVE_TRAY}?SampleNumber=${sampleNo || localSample}&Screen=Edit Sample`);
  }

  const getAllData = async (sampleNo) => {
    const datas = await get(`/details/sample?sampleNo=${sampleNo || localSample}`);
    if (datas) {
      setDropdownData(datas.MasterData || {});
      dispatch({
        type: SampleActions.LOAD_DATA,
        payload: {
          ...datas.Sample,
          ComponentChanged: datas.Sample?.CompChangedEventId > 0 ? true : false,
          ComponentRepaired: datas.Sample?.CompRepairedEventId > 0 ? true : false,
          FluidChanged: datas.Sample?.FluidChangedEventId > 0 ? true : false,
          MeterChanged: datas.Sample?.MeterChangedEventId > 0 ? true : false,
          CompChangedDate: datas?.Sample?.CompChangedDate || datas?.Sample?.Sampled,
          FluidChangedDate: datas?.Sample?.FluidChangedDate || datas?.Sample?.Sampled,
          CompRepairedDate: datas?.Sample?.CompRepairedDate || datas?.Sample?.Sampled,
          MeterChangedDate: datas?.Sample?.MeterChangedDate || datas?.Sample?.Sampled,
          _tskey: new Date(),
        }
      });
      return;
    }
  }

  const fetchAllFluids = async () => {
    const allFluid = await getMasterData("Fluids", `&fluidTypeId=${0}`);
    setDropdownData({ ...dropdownData, Fluids: allFluid?.Fluids });
  }

  const handleDDChange = (e, val, name) => {
    dispatch({
      type: FormActions.INPUT_CHANGED,
      payload: { name: name, value: val }
    });
  }

  useEffect(() => {
    let regData = JSON.parse(localStorage.getItem("edit-sample"));
    if (sampleNo > 0) { getAllData(sampleNo) }
    else if (regData?.sampleNumber > 0) {
      getAllData(regData?.sampleNumber)
      setLocalSample(regData?.sampleNumber)
      setLocalData(regData);
      setHistCheck(regData?.historySample)
      localStorage.removeItem("edit-sample");
    }
  }, []);


  const handleCloseButton = () => {
    localStorage.removeItem("edit-sample");
  }

  useEffect(function () {
    window.addEventListener("beforeunload", handleCloseButton);
    return function () {
      window.removeEventListener("beforeunload", handleCloseButton);
    };
  });

  return (
    <>
      <Card sx={{ p: 1 }}>
        <Form
          sections={GetFormSections(handleDDChange, data, dropdownData,)}
          data={data}
          spacing={2}
          dispatch={dispatch}
          key={data._tskey}
        />
        <Grid container>
          <Grid item xs={12}>
            {(CanDoDataMaintenance || CanLaboratoryTrays || CanEvaluateNotTrainee) && <FormFooter
              footerText={`ProductId: ${dropdownData?.SampleChain?.ProductId || ""}, CustomerId: ${dropdownData?.SampleChain?.CustomerId || ""
                }, SiteId: ${data?.SiteId || ""}, UnitId: ${data?.UnitId || ""
                } , ComponentId: ${data?.ComponentId || ""} , SampleNumber: ${data?.SampleNumber || ""
                } `}
            />}
          </Grid>
        </Grid>

        <Grid container>
          {((data.IsTrainingSample === true && CanDoDataMaintenance === true) || ((data?.Step >= 8) === false && CanEvaluate === true) || CanAdministrate === true) &&
            <Grid item xs={1}>
              <Button color="danger" onClick={actions?.deleteSample}>Delete</Button>
            </Grid>
          }

          <Grid item flex={1}>
            {(CanDoDataMaintenance || CanLaboratoryTrays || CanEvaluateNotTrainee) && <FormFooter
              buttons={GetFormButtons(data, actions, CanEvaluateNotTrainee, CanDoDataMaintenance, CanLaboratoryTrays)} />}
          </Grid>
        </Grid>
      </Card>

      <>
        <AppDialog
          open={openTray}
          handleClose={() => setOpenTray(false)}
          title="Edit Tray"
          maxWidth="xl"
        >
          <EditTray eventFilter={data} />
        </AppDialog>

        <AppDialog
          open={openFluid}
          handleClose={() => setOpenFluid(false)}
          title="New Fluid"
          maxWidth="xl"
        >
          <NewFluid
            cancel={() => setOpenFluid(false)}
            fetchFluidsDetails={fetchAllFluids}
          // rowManageSelected={data}
          />
        </AppDialog>

        <AppDialog
          open={openVersion}
          handleClose={() => setOpenVersion(false)}
          title="New Version"
          maxWidth="xl"
        >
          <NewVersion
            sampleId={data && data.SampleNumber}
            cancel={() => setOpenVersion(false)}
          />
        </AppDialog>

        <AppDialog
          open={imagePickerOpen}
          handleClose={() => setImagePickerOpen(false)}
          title="Add Image"
          maxWidth="xl"
        >
          <ImagePick sampleId={data.SampleNumber} />
        </AppDialog>

        <AppDialog
          open={openManagement}
          title={`Sample Management  Sample ${data.SampleNumber}`}
          maxWidth="100vw"
          handleClose={() => setOpenManagement(false)}
        >
          <ManageSample unit={data} />
        </AppDialog>

        <AppDialog
          open={openAlert?.open}
          title={openAlert?.title}
          maxWidth="md"
          handleClose={() => { setOpenAlert({ open: false }); }}
        >
          <ConfirmModal
            handleClose={() => { setOpenAlert({ open: false }); }}
            alertAction={openAlert?.handleFunction}
            message={openAlert?.message}
            buttonText={"Yes"}
            buttonText2={"No"} />
        </AppDialog>
      </>
    </>
  );
}

const GetFormButtons = (data, { versionClick, fluidClick, trayClick, save, imageOpen, manage, resendSample, resendTray }, CanEvaluateNotTrainee, CanDoDataMaintenance, CanLaboratoryTrays) => [
  {
    variant: "outlined",
    label: "Edit Tray",
    onClick: trayClick,
  },
  {
    hidden: (!CanEvaluateNotTrainee || data?.Step < 8),
    variant: "outlined",
    label: "New Version",
    onClick: versionClick,
  },
  {
    variant: "outlined",
    label: "New Fluid",
    onClick: fluidClick,
  },
  {
    variant: "outlined",
    label: "Manage",
    onClick: manage,
  },
  {
    label: "Images",
    onClick: imageOpen,
    variant: "outlined",
    startIcon: <AddAPhoto />,
  },
  {
    hidden: (!CanEvaluateNotTrainee || data?.Step < 8),
    label: "Resend (Sample)",
    onClick: resendSample,
    variant: "outlined",
  },
  {
    hidden: (!CanEvaluateNotTrainee || data?.Step < 8),
    label: "Resend (Tray)",
    onClick: resendTray,
    variant: "outlined",
  },
  {
    hidden: !(CanDoDataMaintenance || CanLaboratoryTrays),
    label: "Save",
    onClick: save,
  },
];

function GetFormSections(handleDDChange, data, dropdownData,) {
  let componentDetails = dropdownData?.Component;
  return [
    {
      xsCol: 12,
      fields: [
        {
          xsCol: 12,
          component: function () {
            return (
              <Typography>{` ${componentDetails?.Product || ""}, ${componentDetails?.Customer || ""}, ${componentDetails?.Site || ""}, ${componentDetails?.Unit || ""}  (${componentDetails?.UnitModelFormatted || ""} ${componentDetails?.UnitSerial || ""}), ${componentDetails?.Component || ""}`}</Typography>
            );
          },
        },
        {
          xsCol: 12,
          sx: { paddingTop: 0 },
          component: function () {
            return (
              <Typography
                sx={{ color: "purple" }}
              >{`If anything is UNKNOWN. e.g. Meter Reading, then make the value EMPTY and NOT ZERO; zero means that the value is known and brand new`}</Typography>
            );
          },
        },
        {
          name: "SampleNumber",
          label: "Sample Number",
          xsCol: 2,
          readOnly: true,
          sx: {
            [`.MuiInputBase-root`]: {
              backgroundColor: data.AlertColour !== null ? data.AlertColour : "",
              color: data.AlertColour !== null ? "white" : "",
            },
          },
          // AlertColour
        },
        { name: "SIF", label: "Registration", xsCol: 2, readOnly: true },

        {
          name: "Step",
          label: "Step",
          xsCol: 2,
          readOnly: true,
          getValue: (data) =>
            data.Step ? `${GetStepNames(data.Step).long} : ${data.Step}` : "",
        },
        {
          name: "OwnerLaboratoryId",
          label: "Tray Owner",
          xsCol: 2,
          type: "dropdown",
          selectOptions: (dropdownData.Laboratories || []).map((x) => ({
            value: x.LaboratoryId,
            text: x.Laboratory,
          })),
        },
        {
          xsCol: 4,
          group: [
            { name: "Sampled", label: "Sampled", xsCol: 6, type: "datetime" },
            {
              name: "SampledAssumed",
              label: "Assumed",
              type: "switch",
              xsCol: 6,
            },
          ],
        },
        {
          name: "Released",
          label: "Laboratory",
          xsCol: 2,
          readOnly: true,
          type: "datetime",
        },
        {
          name: "TrayDateDate",
          label: "Tray Date",
          xsCol: 2,
          readOnly: true,
          type: "datetime",
        },
        {
          name: "RegistrationCreated",
          label: "Card Created",
          xsCol: 2,
          readOnly: true,
          type: "datetime",
        },
        {
          name: "QualityChecked",
          label: "Quality Check",
          xsCol: 2,
          readOnly: true,
          type: "datetime",
        },
        {
          name: "Released",
          label: "Released",
          xsCol: 2,
          readOnly: true,
          type: "datetime",
        },
      ],
    },
    {
      xlCol: 12,
      fields: [
        {
          xsCol: 6,
          group: [
            {
              name: "MeterChanged",
              label: "Meter Changed",
              value: true,
              type: "switch",
              xsCol: 4,
            },
            {
              name: "MeterChangedHours",
              label: "Changed At",
              xsCol: 4,
              disabled: !data.MeterChanged,
            },
            {
              name: "MeterChangedDate",
              label: "Approx. Changed",
              type: "datetime",
              xsCol: 4,
              disabled: !data.MeterChanged,
            },
          ],
        },
        {
          xsCol: 6,
          group: [
            {
              name: "ComponentChanged",
              label: "Component Changed",
              type: "switch",
              xsCol: 4,
            },
            {
              name: "CompChangedHours",
              label: "Changed At",
              xsCol: 4,
              disabled: !data.ComponentChanged,
            },
            {
              name: "CompChangedDate",
              label: "Approx. Changed",
              type: "datetime",
              xsCol: 4,
              disabled: !data.ComponentChanged,
            },
          ],
        },
        {
          xsCol: 6,
          group: [
            {
              name: "ComponentRepaired",
              label: "Component Repaired",
              type: "switch",
              xsCol: 4,
            },
            {
              name: "CompRepairedHours",
              label: "Repaired At",
              xsCol: 4,
              disabled: !data.ComponentRepaired,
            },
            {
              name: "CompRepairedDate",
              label: "Approx. Repaired",
              type: "datetime",
              xsCol: 4,
              disabled: !data.ComponentRepaired,
            },
          ],
        },
        {
          xsCol: 6,
          group: [
            {
              name: "FluidChanged",
              label: "Fluid Changed",
              type: "switch",
              xsCol: 4,
            },
            {
              name: "FluidChangedHours",
              label: "Changed At",
              xsCol: 4,
              disabled: !data.FluidChanged,
            },
            {
              name: "FluidChangedDate",
              label: "Approx. Changed",
              type: "datetime",
              xsCol: 4,
              disabled: !data.FluidChanged,
            },
          ],
        },
      ],
    },
    {
      xsCol: 12,
      fields: [
        {
          name: "FluidHours", label: "Fluid Hours", xsCol: 2, type: "number", min0: true,
        },
        {
          name: "SampleOptionId",
          label: "Kidney Loop",
          xsCol: 2,
          type: "dropdown",
          selectOptions: (dropdownData.SampleOptions || []).map((x) => ({
            value: x.SampleOptionId,
            text: x.SampleOption,
          })),
        },
        {
          name: "Topup", label: "Topup", xsCol: 2, type: "number", min0: true,
        },
        {
          name: "FilterChanged",
          label: "Filter Changed",
          xsCol: 2,
          type: "switch",
        },

        {
          name: "ServiceTypeId",
          label: "Service",
          xsCol: 2,
          type: "dropdown",
          selectOptions: (dropdownData.ServiceTypes || []).map((x) => ({
            value: x.ServiceTypeId,
            text: x.ServiceType,
          })),
        },
        { name: "JobNumber", label: "Job Number", xsCol: 2 },
        {
          xsCol: 4,
          group: [
            {
              name: "MeterReading",
              label: "Meter Reading",
              xsCol: 6,
              type: "number",
              min0: true,
              min: 0,
            },
            {
              name: "MeterReadingAssumed",
              label: "Assumed",
              type: "switch",
              xsCol: 6,
            },
          ],
        },

        {
          xsCol: 4,
          group: [
            {
              name: "ComponentReading",
              label: "Component Reading",
              xsCol: 6,
              type: "number",
              min0: true,
            },
            {
              name: "ComponentReadingAssumed",
              label: "Assumed",
              type: "switch",
              xsCol: 6,
            },
          ],
        },
        {
          xsCol: 4,
          group: [
            {
              name: "TestSetId",
              label: "Test Set",
              xsCol: 6,
              type: "dropdown",
              selectOptions: (dropdownData.TestSetsForUnitFluidType || []).map(
                (x) => ({
                  value: x.TestSetId,
                  text: x.TestSet,
                })
              ),
            },
            {
              name: "TestSetMakeDefault",
              label: "Make Default",
              type: "switch",
              xsCol: 6,
            },
          ],
        },
        {
          xsCol: 2,
          alignItems: "flex-end",
          component: function () {
            return (
              <Typography>Unit Hours: <b>{` ${data?.UnitHours || ""}`}</b></Typography>
            );
          },
        }, {
          xsCol: 2,
          alignItems: "flex-end",
          component: function () {
            return (
              <Typography>Component Hours: <b>{` ${data?.ComponentHours || ""}`}</b></Typography>
            );
          },
        },
        // { name: "UnitHours", label: "Unit Hours", readOnly: true, xsCol: 2 },
        // {
        //   name: "ComponentHours",
        //   label: "Component Hours",
        //   xsCol: 2,
        //   readOnly: true,
        // },
        /////////////////////////////////////////////////////////////////////////////
        {
          xsCol: 4,
          group: [
            {
              name: "Delivered",
              label: "Delivered",
              xsCol: 6,
              type: "date",
              disabled: !data.UseDelivered,
            },

            {
              name: "UseDelivered",
              label: "Use Delivered",
              type: "switch",
              xsCol: 6,
            },
          ],
        },

        {
          name: "Evaluated",
          label: "Evaluation",
          xsCol: 2,
          readOnly: true,
          type: "datetime",
        },
        {
          name: "SampleTypeId",
          label: "Kit Type",
          xsCol: 2,
          type: "dropdown",
          selectOptions: (dropdownData.SampleTypes || []).map((x) => ({
            value: x.SampleTypeId,
            text: x.SampleType,
          })),
        },
        {
          xsCol: 6,
          group: [
            {
              name: "FluidType",
              label: "Fluid Type",
              xsCol: 4,
              readOnly: true,
            },
            {
              xsCol: 4,
              component: function () {
                return (
                  <FluidsDropDown
                    id={"FluidId"}
                    dataList={dropdownData.Fluids || []}
                    handleChange={(e, val, text) => handleDDChange(e, val, text)}
                    defaultValue={data?.FluidId}
                  />
                );
              },
            },
            {
              name: "MakeFluidDefault",
              label: "Make Default",
              type: "switch",
              xsCol: 4,
            },
          ],
        },
        {
          name: "ApplyFluidToAllSamples",
          label: "Apply To All Samples",
          type: "switch",
          xsCol: 2,
        },
        {
          xsCol: 2,
          label: "Internal (no website)",
          name: "IsInternalOnly",
          type: "switch",
        },
        {
          xsCol: 2,
          label: "Archived - no website",
          name: "IsArchived",
          type: "switch",
        },
      ],
    },
    {
      xsCol: 12,
      fields: [
        {
          name: "CustomerComments",
          label: "Registration Comments: internal only",
          xsCol: 6,
          type: "textarea",
          rows: 4,
        },
        {
          name: "SampleNotes",
          label: "Sample Comments: internal only",
          xsCol: 6,
          type: "textarea",
          rows: 4,
        },
        {
          name: "Evaluation",
          label: "Evaluation: on report",
          label2: `Technician: ${data?.Evaluator}\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0\xa0`,
          label2Color: "black",
          xsCol: 6,
          type: "textarea",
          readOnly: true,
          rows: 4,
        },
        {
          name: "Recommendation",
          label: "Recommendation: on report",
          xsCol: 6,
          type: "textarea",
          readOnly: true,
          rows: 4,
        },
      ],
    },
  ];
}

function FluidsDropDown({ id, dataList, handleChange, defaultValue }) {
  const renderOptions = useCallback(function (props, option) {
    return (
      <Box component="li" sx={{ minWidth: "380px", maxWidth: "380px" }} {...props} key={option.FluidId}>
        {option.FluidFormatted || "Select"}
      </Box>
    );
  }, []);

  const filterOptions = useCallback(function (options, value) {
    return !value.inputValue
      ? options
      : options
        .filter((x) =>
          x.FluidFormatted.toLowerCase().includes(
            value.inputValue.toLowerCase()
          )
        )
  }, []);

  const getOptionLabel = useCallback(function (option) {
    return option.FluidFormatted || "Select";
  }, []);

  return (
    <DropDownAdvance
      id={id}
      label="Fluid"
      renderOptions={renderOptions}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      onChange={(e, val) => handleChange(e, val?.FluidId || null, "FluidId")}
      options={dataList || []}
      defaultValue={dataList.find((x) => x.FluidId === defaultValue) || "Select"}
    />
  );
}
