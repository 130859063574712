import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ClassNames } from "@emotion/react";
import {
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { DETAILS_APIS, FLEET_APIS } from "../../../../common/apis";
import useFetch from "../../../../hooks/useFetch";
import SearchComponent from "../search/SearchComponent";
import { useUser } from "../../../../rootContext";
import { useEffect } from "react";
import { useState } from "react";
import SearchUnit from "../search/SearchUnit";

const steps = [
  "Select Action",
  "Select Destination Component",
  "Action Summary",
];

export default function ManageComponent({ unit, close, handleSearchTree }) {

  const { post, get } = useFetch();
  const user = useUser();

  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedUnit, setSelectedUnit] = React.useState({});
  const [skipped, setSkipped] = React.useState(new Set());
  const [processResponse, setProcessResponse] = React.useState("Process completed successfully.");
  const [selectedOption, setSelectedOption] = React.useState("deleteOriginal");
  const [componentDetails, setComponentDetails] = useState({})
  const [keepContacts, setKeepContacts] = useState(false);
  const [localData, setLocalData] = useState({});

  const getComponentDetails = async (compId) => {
    const respData = await get(`${DETAILS_APIS.MANAGE_COMPONENT_CHAIN}?ComponentId=${compId}`);
    setComponentDetails(respData)
    document.title = (`Manage Component ${respData?.Component}`);
  }

  useEffect(() => {
    let parseData = JSON.parse(localStorage.getItem("manage-component"));

    if (parseData !== null && parseData !== undefined && parseData?.ComponentId > 0) {
      setLocalData(parseData);
      getComponentDetails(parseData?.ComponentId)
    }

    if (unit?.ComponentId > 0) {
      getComponentDetails(unit?.ComponentId)
    }
  }, [])

  async function PerformOperation() {

    if (selectedOption === "deleteOriginal") {
      const datas = {
        OriginalComponentMakeId: unit?.ComponentId || localData?.ComponentId,
        ReplacementComponentMakeId: selectedUnit?.ComponentId,
        ActionToTake: 2,
        Screen: "Component Management",
        UserId: user?.UserId,
        PreserveContacts: keepContacts,
      };

      const result = await post(FLEET_APIS.MANAGE_COMPONENT, datas);
      if (result.Success === true) {
        setProcessResponse(result);
        close && close();
        const treeData = {
          ProductId: selectedUnit?.ProductId,
          StartLetter: selectedUnit?.StartLetter,
          CustomerId: selectedUnit?.CustomerId,
          SiteId: selectedUnit.SiteId,
          UnitId: selectedUnit.UnitId,
          ComponentId: selectedUnit.ComponentId,
          refreshCheck: true
        };
        handleSearchTree && handleSearchTree(treeData, null, true)
        localStorage.setItem("tree-resp", JSON.stringify({ ...treeData }));
        window.dispatchEvent(new Event('storage'));

        if (window.location.pathname === '/manage-component') {
          localStorage.removeItem("manage-component");
          window.dispatchEvent(new Event('storage'));
          window.close();
        }
      }
    }
    if (selectedOption === 'keepOriginal') {
      const datas = {
        OriginalComponentMakeId: unit?.ComponentId || localData?.ComponentId,
        ReplacementComponentMakeId: selectedUnit.ComponentId,
        ActionToTake: 1,
        Screen: "Component Management",
        UserId: user?.UserId,
        PreserveContacts: keepContacts,
      };
      const result = await post(FLEET_APIS.MANAGE_COMPONENT, datas);
      if (result.Success === true) {
        setProcessResponse(result);
        close && close();
        const treeData = {
          ProductId: selectedUnit?.ProductId,
          StartLetter: selectedUnit?.StartLetter,
          CustomerId: selectedUnit?.CustomerId,
          SiteId: selectedUnit.SiteId,
          UnitId: selectedUnit.UnitId,
          ComponentId: selectedUnit.ComponentId,
          refreshCheck: true
        };
        handleSearchTree && handleSearchTree(treeData, null, true)
        localStorage.setItem("tree-resp", JSON.stringify({ ...treeData }));
        window.dispatchEvent(new Event('storage'));

        if (window.location.pathname === '/manage-component') {
          localStorage.removeItem("manage-component");
          window.dispatchEvent(new Event('storage'));
          window.close();
        }
      }
    }
    else if (selectedOption === "moveComponent") {
      const datas = {
        OriginalComponentMakeId: unit?.ComponentId || localData?.ComponentId,
        ReplacementComponentMakeId: selectedUnit.ComponentId,
        ActionToTake: 3,
        UnitId: selectedUnit.UnitId,
        Screen: "Component Management",
        UserId: user?.UserId,
        PreserveContacts: keepContacts,
      };
      const result = await post(FLEET_APIS.MANAGE_COMPONENT, datas);
      if (result.Success === true) {
        setProcessResponse(result);
        close && close();
        const treeData = {
          ProductId: selectedUnit?.ProductId,
          StartLetter: selectedUnit?.StartLetter,
          CustomerId: selectedUnit?.CustomerId,
          SiteId: selectedUnit.SiteId,
          UnitId: selectedUnit.UnitId,
          ComponentId: selectedUnit.ComponentId,
          refreshCheck: true
        }
        handleSearchTree && handleSearchTree(treeData, null, true)
        localStorage.setItem("tree-resp", JSON.stringify({ ...treeData }));
        window.dispatchEvent(new Event('storage'));

        if (window.location.pathname === '/manage-component') {
          localStorage.removeItem("manage-component");
          window.dispatchEvent(new Event('storage'));
          window.close();
        }
      }
    }
    else if (selectedOption === "deleteComponent") {
      const datas = {
        OriginalComponentMakeId: unit?.ComponentId || localData?.ComponentId,
        ReplacementComponentMakeId: selectedUnit.ComponentId,
        ActionToTake: 4,
        Screen: "Component Management",
        UserId: user?.UserId,
        PreserveContacts: keepContacts,
      };

      const treeData = {
        ProductId: unit?.ProductId || localData?.ProductId,
        StartLetter: unit?.StartLetter || localData?.StartLetter,
        CustomerId: unit?.CustomerId || localData?.CustomerId,
        SiteId: unit?.SiteId || localData?.SiteId,
        UnitId: unit?.UnitId || localData?.UnitId,
      }

      const result = await post(FLEET_APIS.MANAGE_COMPONENT, datas);
      if (result.Success === true) {
        setProcessResponse(result);
        close && close();
        handleSearchTree && handleSearchTree(treeData, null, true)
        localStorage.setItem("tree-resp", JSON.stringify({ ...treeData }));
        window.dispatchEvent(new Event('storage'));

        if (window.location.pathname === '/manage-component') {
          localStorage.removeItem("manage-component");
          window.dispatchEvent(new Event('storage'));
          window.close();
        }
      }
    }
  }

  const handleNext = () => {
    let newSkipped = skipped;
    if (activeStep === 2) {
      PerformOperation();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };
  const handleBack = () => {
    if (selectedOption === "deleteComponent") {
      setActiveStep((prevActiveStep) => prevActiveStep - 2);
      setSelectedOption("deleteOriginal");
      setSelectedUnit({});
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setSelectedUnit({});
    }
  };
  const handleRadioGroupChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleClose = () => {
    close();
  };
  // Table's Styling
  const tableCol2 = {
    background: "lightblue",
    color: "blue",
  };
  const tableCol3 = {
    background: "lightgreen",
    color: "green",
  };

  const handleSelectDestination = async (row) => {
    const respData = await get(`${DETAILS_APIS.MANAGE_COMPONENT_CHAIN}?ComponentId=${row?.ComponentId}`);
    setSelectedUnit(respData)
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const handleSelectUnitDestination = async (row) => {
    const respData = await get(`${DETAILS_APIS.MANAGE_UNIT_CHAIN}?unitId=${row?.UnitId}`);
    setSelectedUnit(respData)
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="deleteOriginal"
              name="manageAction"
              onChange={handleRadioGroupChange}
            >
              <FormControlLabel
                value="deleteOriginal"
                control={<Radio />}
                label="Merge the component into a different component, and DELETE the original component"
              />
              <FormControlLabel
                value="keepOriginal"
                control={<Radio />}
                label="Merge the component into a different component, and KEEP the original component"
              />
              <FormControlLabel
                value="moveComponent"
                control={<Radio />}
                label="Move the component to a different unit"
              />
              {(user?.RoleAdministration === false && user?.RoleDeveloper === false)
                ? <>
                  <FormControlLabel
                    hidden={!(componentDetails?.SampleCount === 0 && componentDetails?.AdHocReportCount === 0)}
                    value="deleteComponent"
                    control={<Radio />}
                    label="Delete the component "
                  />
                </>
                : (componentDetails?.SampleCount > 0 || componentDetails?.AdHocReportCount > 0)
                  ? <>
                    <FormControlLabel
                      hidden={!(componentDetails?.CanDeleteSamples || user?.RoleAdministration || user?.RoleDeveloper)}
                      value="deleteComponent"
                      control={<Radio />}
                      label={"Delete the component and DELETE " + (componentDetails?.SampleCount > 0 ? componentDetails?.SampleCount + " sample" + (componentDetails?.SampleCount !== 1 ? "s" : "") : "") +
                        (componentDetails?.SampleCount > 0 && componentDetails?.AdHocReportCount > 0 ? " and " : "") +
                        (componentDetails?.AdHocReportCount > 0 ? componentDetails?.AdHocReportCount + " special report " + (componentDetails?.AdHocReportCount !== 1 ? "s" : "") : "")}
                    />
                  </>
                  : <>
                    <FormControlLabel
                      value="deleteComponent"
                      control={<Radio />}
                      label="Delete the component "
                    />
                  </>
              }
            </RadioGroup>
            <Typography mt={1}>Options:</Typography>
            <FormControlLabel
              sx={{ "& .MuiFormControlLabel-label": { color: keepContacts === true ? "#228B22" : "#BF40BF" } }}
              checked={keepContacts}
              control={<Checkbox />}
              onChange={() => setKeepContacts(!keepContacts)}
              label="Keep all contacts that are on the Customer, Sites, Units or Components"
            />
          </FormControl>
        );

      case 1:
        return selectedOption === "deleteComponent"
          ? (handleNext())
          : (selectedOption === "moveComponent"
            ? <SearchUnit
              selectedUnit={componentDetails}
              actions={[
                {
                  action: handleSelectUnitDestination,
                  label: "Select",
                },
              ]}
            // rowSelected={(row) => setSelectedUnit(row)}
            /> :
            <SearchComponent
              selectedUnit={selectedOption === "moveComponent" ? {} : componentDetails}
              actions={[
                {
                  action: handleSelectDestination,
                  label: "Select",
                },
              ]}
            // rowSelected={(row) => handleSelectDestination(row)}
            />
          );

      case 2:
        return selectedOption === "deleteComponent" ? (
          <>
            <Typography sx={{ mb: 1 }}>
              The Component {unit?.Component} will be DELETED
            </Typography>
            <TableContainer sx={{ mb: 1 }} component={Paper}>
              <Table
                sx={{ textAlign: "left" }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    {/* Col 1 */}
                    <TableCell>Item</TableCell>
                    {/* Col 2 */}
                    <TableCell sx={tableCol2}>Source</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Product
                    </TableCell>
                    <TableCell sx={tableCol2}>{componentDetails?.Product || ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Customer
                    </TableCell>
                    <TableCell sx={tableCol2}>{componentDetails?.Customer || ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Site
                    </TableCell>
                    <TableCell sx={tableCol2}>{unit?.Site || ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Unit
                    </TableCell>
                    <TableCell sx={tableCol2}>{componentDetails?.Unit || ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Component
                    </TableCell>
                    <TableCell sx={tableCol2}> {unit?.Component || ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      ComponentId
                    </TableCell>
                    <TableCell sx={tableCol2}> {unit?.ComponentId || ""} </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Sample Count
                    </TableCell>
                    <TableCell sx={tableCol2}> {unit?.SampleCount || 0}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Events Count
                    </TableCell>
                    <TableCell sx={tableCol2}>  {unit?.EventsCount || 0} </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Notes Count
                    </TableCell>
                    <TableCell sx={tableCol2}> {unit?.NotesCount || 0} </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Special Reports Count
                    </TableCell>
                    <TableCell sx={tableCol2}>{unit?.SpecialReportsCount || 0} </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <>
            <TableContainer sx={{ mb: 1 }} component={Paper}>
              <Table
                sx={{ textAlign: "left" }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    {/* Col 1 */}
                    <TableCell>Item</TableCell>
                    {/* Col 2 */}
                    <TableCell sx={tableCol2}>Source</TableCell>
                    {/* Col 3 */}
                    <TableCell sx={tableCol3}>Destination</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Product
                    </TableCell>
                    <TableCell sx={tableCol2}>{componentDetails?.Product || ""}</TableCell>
                    <TableCell sx={tableCol3}>  {selectedUnit?.Product} </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Customer
                    </TableCell>
                    <TableCell sx={tableCol2}>{componentDetails?.Customer || ""}</TableCell>
                    <TableCell sx={tableCol3}>  {selectedUnit?.Customer}  </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Site
                    </TableCell>
                    <TableCell sx={tableCol2}>{componentDetails?.Site || ""}</TableCell>
                    <TableCell sx={tableCol3}> {selectedUnit?.Site || ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Unit
                    </TableCell>
                    <TableCell sx={tableCol2}>{componentDetails?.Unit || ""}</TableCell>
                    <TableCell sx={tableCol3}> {selectedUnit?.Unit || ""}   </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Component
                    </TableCell>
                    <TableCell sx={tableCol2}>  {componentDetails?.Component || ""}     </TableCell>
                    <TableCell sx={tableCol3}>   {selectedUnit?.Component || ""} </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      ComponentId
                    </TableCell>
                    <TableCell sx={tableCol2}>{componentDetails?.ComponentId || ""} </TableCell>
                    <TableCell sx={tableCol3}>   {selectedUnit?.ComponentId || ""} </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Sample Count
                    </TableCell>
                    <TableCell sx={tableCol2}>   {componentDetails?.SampleCount || 0} </TableCell>
                    <TableCell sx={tableCol3}>   {selectedUnit?.SampleCount || 0} </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Events Count
                    </TableCell>
                    <TableCell sx={tableCol2}>   {componentDetails?.EventsCount || 0} </TableCell>
                    <TableCell sx={tableCol3}>   {selectedUnit?.EventsCount || 0} </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Notes Count
                    </TableCell>
                    <TableCell sx={tableCol2}>   {componentDetails?.NotesCount || 0} </TableCell>
                    <TableCell sx={tableCol3}>   {selectedUnit?.NotesCount || 0} </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Special Reports Count
                    </TableCell>
                    <TableCell sx={tableCol2}>   {componentDetails?.SpecialReportsCount || 0} </TableCell>
                    <TableCell sx={tableCol3}>   {selectedUnit?.SpecialReportsCount || 0} </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        );
      default:
        return "Unknown step";
    }
  }
  return (
    <Card
      sx={{
        padding: "10px",
        minWidth: "70vw",
        minHeight: "23vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Stepper
        nonLinear={selectedOption === "deleteComponent" ? true : false}
        activeStep={activeStep}
        sx={{ mr: 2 }}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: "-20%" }}>{processResponse}</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button onClick={handleClose} sx={{ ml: "auto", mr: 1 }}>
              Close
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Typography className={ClassNames.instructions} sx={{ mt: 4 }}>
            {getStepContent(activeStep)}
          </Typography>
          <Box
            sx={{
              marginTop: "auto",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </Button>
            {activeStep !== 1
              ? <Button
                onClick={handleNext}
                disabled={activeStep === 1 && !selectedUnit?.Customer}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
              : <></>}
          </Box>
        </>
      )}
    </Card>
  );
}
