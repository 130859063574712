import React from "react";
import { Box, Card, Grid, Typography } from "@mui/material";
import footerLogo from "../Image/PoweredbyKPS.svg";
import aboutLogo from "../Image/Logo-About.svg";

const AboutDialog = () => {
  return (
    <>
      <Card
        sx={{
          p: 3,
          minWidth: "30vw",
          maxWidth: "30vw",
          height: "20vh",
          overflow: "hidden",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Card>
              <Box
                sx={{
                  display: "block",
                  mx: "auto",
                  py: "10px",
                }}
                component="img"
                alt="KOWA"
                src={aboutLogo}
              />
            </Card>
          </Grid>

          <Grid item xs={9} mt={2}>
            <Typography fontWeight={500} sx={{ fontSize: "18px" }}>
              Komatsu Oil and Wear Analysis
            </Typography>
            <Typography fontWeight={500}>Version 2.0.54</Typography>
          </Grid>

          <Grid item xs={12} mt={2}>
            <Typography fontWeight={500} fontSize={"12px"}>
              For any queries, please contact:{" "}
              <a
                href="mailto:kps-helpdesk_kowa@global.komatsu"
                style={{ textDecoration: "none" }}
              >
                {" "}
                <i> kps-helpdesk_kowa@global.komatsu</i>
              </a>
            </Typography>
          </Grid>

        </Grid>
      </Card>

      <Grid container sx={{ mb: -2 }}>
        <Grid item xs={4}>
          <Typography
            fontWeight={500}
            sx={{ fontSize: "15px", mt: 1.5 }}
          ></Typography>
        </Grid>

        <Grid item xs={8}>
          <a
            style={{ float: "right" }}
            href="https://home.kps.komatsu/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box
              component="img"
              sx={{
                height: 30,
                pt: 1,
              }}
              alt="KOWA"
              src={footerLogo}
            />
          </a>
        </Grid>
      </Grid>
    </>
  );
};

export default AboutDialog;
