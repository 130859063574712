import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ClassNames } from "@emotion/react";
import {
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { DETAILS_APIS, FLEET_APIS } from "../../../../common/apis";
import useFetch from "../../../../hooks/useFetch";
import SearchUnit from "../search/SearchUnit";
import { useUser } from "../../../../rootContext";
import { useEffect } from "react";
import { useState } from "react";
import SearchSite from "../search/SearchSite";

const steps = ["Select Action", "Select Destination Unit", "Action Summary"];

export default function ManageUnit({ unit, close, handleSearchTree }) {
  const { get, post } = useFetch();
  const user = useUser();

  const [activeStep, setActiveStep] = useState(0);
  const [selectedUnit, setSelectedUnit] = useState({});
  const [skipped, setSkipped] = useState(new Set());
  const [processResponse, setProcessResponse] = useState("Process completed successfully.");
  const [selectedOption, setSelectedOption] = useState("deleteOriginal");
  const [unitDetails, setUnitDetails] = useState({});
  const [keepContacts, setKeepContacts] = useState(false);
  const [localData, setLocalData] = useState({});

  const getUnitDetails = async (unit) => {
    const respData = await get(`${DETAILS_APIS.MANAGE_UNIT_CHAIN}?unitId=${unit}`);
    setUnitDetails(respData)
    document.title = (`Manage Unit ${respData?.Unit}`);
  }

  useEffect(() => {
    let parseData = JSON.parse(localStorage.getItem("manage-unit"));
    if (parseData !== null && parseData !== undefined && parseData?.UnitId > 0) {
      setLocalData(parseData);
      getUnitDetails(parseData?.UnitId)
    }
  }, [])

  useEffect(() => {
    if (unit?.UnitId > 0) {
      getUnitDetails(unit?.UnitId)
    }
  }, [unit])

  async function PerformOperation() {

    if (selectedOption === "deleteOriginal") {
      const data = {
        OriginalUnitMakeId: unit?.UnitId || localData?.UnitId,
        ReplacementUnitMakeId: selectedUnit.UnitId,
        ActionToTake: 2,
        Screen: "Unit Management",
        UserId: user?.UserId,
        PreserveContacts: keepContacts,
      };
      const result = await post(FLEET_APIS.MANAGE_UNIT, data);
      if (result.Success === true) {
        setProcessResponse(result);
        close && close();
        const treeData = { ProductId: selectedUnit?.ProductId, StartLetter: selectedUnit?.StartLetter, CustomerId: selectedUnit?.CustomerId, SiteId: selectedUnit.SiteId, UnitId: selectedUnit.UnitId, refreshCheck: true }
        handleSearchTree && handleSearchTree(treeData, null, true)

        localStorage.setItem("tree-resp", JSON.stringify({ ...treeData }));
        window.dispatchEvent(new Event('storage'));

        if (window.location.pathname === '/manage-unit' || window.location.pathname === '/edit-unit') {
          localStorage.removeItem("manage-unit");
          window.dispatchEvent(new Event('storage'));
          window.close();
        }
      }
    }
    else if (selectedOption === 'keepOriginal') {
      const data = {
        OriginalUnitMakeId: unit?.UnitId || localData?.UnitId,
        ReplacementUnitMakeId: selectedUnit.UnitId,
        ActionToTake: 1,
        Screen: "Unit Management",
        UserId: user?.UserId,
        PreserveContacts: keepContacts,
      };
      const result = await post(FLEET_APIS.MANAGE_UNIT, data);
      if (result.Success === true) {
        setProcessResponse(result);
        close && close();
        const treeData = { ProductId: selectedUnit?.ProductId, StartLetter: selectedUnit?.StartLetter, CustomerId: selectedUnit?.CustomerId, SiteId: selectedUnit.SiteId, UnitId: selectedUnit.UnitId, refreshCheck: true }
        handleSearchTree && handleSearchTree(treeData, null, true)
        localStorage.setItem("tree-resp", JSON.stringify({ ...treeData }));
        window.dispatchEvent(new Event('storage'));

        if (window.location.pathname === '/manage-unit' || window.location.pathname === '/edit-unit') {
          localStorage.removeItem("manage-unit");
          window.dispatchEvent(new Event('storage'));
          window.close();
        }
      }
    }
    else if (selectedOption === "moveUnit") {
      const data = {
        OriginalUnitMakeId: unit?.UnitId || localData?.UnitId,
        ReplacementUnitMakeId: selectedUnit.UnitId,
        SiteId: selectedUnit.SiteId,
        ActionToTake: 3,
        Screen: "Unit Management",
        UserId: user?.UserId,
        PreserveContacts: keepContacts,
      };
      const result = await post(FLEET_APIS.MANAGE_UNIT, data);
      if (result.Success === true) {
        setProcessResponse(result);
        close && close();
        const treeData = {
          ProductId: selectedUnit?.ProductId,
          StartLetter: selectedUnit?.StartLetter,
          CustomerId: selectedUnit?.CustomerId,
          SiteId: selectedUnit.SiteId,
          UnitId: selectedUnit.UnitId,
          refreshCheck: true
        }
        handleSearchTree && handleSearchTree(treeData, null, true)
        localStorage.setItem("tree-resp", JSON.stringify({ ...treeData }));
        window.dispatchEvent(new Event('storage'));

        if (window.location.pathname === '/manage-unit' || window.location.pathname === '/edit-unit') {
          localStorage.removeItem("manage-unit");
          window.dispatchEvent(new Event('storage'));
          window.close();
        }
      }
    }
    else if (selectedOption === "deleteUnit") {
      const data = {
        OriginalUnitMakeId: unit?.UnitId || localData?.UnitId,
        ReplacementUnitMakeId: selectedUnit.UnitId,
        ActionToTake: 4,
        Screen: "Unit Management",
        UserId: user?.UserId,
        PreserveContacts: keepContacts,
      };
      const result = await post(FLEET_APIS.MANAGE_UNIT, data);

      const treeData = {
        ProductId: unit?.ProductId || localData?.ProductId,
        StartLetter: unit?.StartLetter >= 0 ? unit?.StartLetter : localData?.StartLetter,
        CustomerId: unit?.CustomerId || localData?.CustomerId,
        SiteId: unit?.SiteId || localData?.SiteId,
      }

      if (result.Success === true) {
        setProcessResponse(result);
        close && close();
        handleSearchTree && handleSearchTree(treeData, null, true)
        localStorage.setItem("tree-resp", JSON.stringify({ ...treeData }));
        window.dispatchEvent(new Event('storage'));

        if (window.location.pathname === '/manage-unit' || window.location.pathname === '/edit-unit') {
          localStorage.removeItem("manage-unit");
          window.dispatchEvent(new Event('storage'));
          window.close();
        }
      }
    }
  }

  const handleNext = () => {
    let newSkipped = skipped;
    if (activeStep === 2) {
      PerformOperation();
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    if (selectedOption === "deleteUnit") {
      setActiveStep((prevActiveStep) => prevActiveStep - 2);
      setSelectedOption("deleteOriginal");
      setSelectedUnit({});
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      setSelectedUnit({});
    }
  };
  const handleRadioGroupChange = (event) => {
    setSelectedOption(event.target.value);
    //setSelectedOption(undefined)
  };
  const handleReset = () => {
    setActiveStep(0);
    close();
    // refreshGrid();
  };

  // Table's Styling
  const tableCol2 = {
    background: "lightblue",
    color: "blue",
  };
  const tableCol3 = {
    background: "lightgreen",
    color: "green",
  };

  const handleSelectDestination = async (row) => {
    const respData = await get(`${DETAILS_APIS.MANAGE_UNIT_CHAIN}?unitId=${row?.UnitId}`);
    setSelectedUnit({ ...respData, UnitId: row?.UnitId })
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  const handleSelectSiteDestination = async (row) => {
    const respData = await get(`${DETAILS_APIS.MANAGE_SITE_CHAIN}?siteId=${row?.SiteId}`);
    setSelectedUnit({ ...respData, UnitId: localData?.UnitId })
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="deleteOriginal"
              name="manageAction"
              //value={selectedOption}
              onChange={handleRadioGroupChange}
            >
              <FormControlLabel
                value="deleteOriginal"
                control={<Radio />}
                label="Merge the unit into a different unit, and DELETE the original unit"
              />
              <FormControlLabel
                value="keepOriginal"
                control={<Radio />}
                label="Merge the unit into a different unit, and KEEP the original unit"
              />
              <FormControlLabel
                value="moveUnit"
                control={<Radio />}
                label="Move the unit to a different site - Written permission is required if to a DIFFERENT CUSTOMER"
              />
              {(user?.RoleAdministration === false && user?.RoleDeveloper === false)
                ? <>
                  <FormControlLabel
                    hidden={!(unitDetails?.SampleCount === 0 && unitDetails?.AdHocReportCount === 0)}
                    value="deleteUnit"
                    control={<Radio />}
                    label="Delete the unit "
                  />
                </>
                : (unitDetails?.SampleCount > 0 || unitDetails?.AdHocReportCount > 0)
                  ? <>
                    <FormControlLabel
                      hidden={!(unitDetails?.CanDeleteSamples || user?.RoleAdministration || user?.RoleDeveloper)}
                      value="deleteUnit"
                      control={<Radio />}
                      label={"Delete the unit and DELETE " + (unitDetails?.SampleCount > 0 ? unitDetails?.SampleCount + " sample" + (unitDetails?.SampleCount !== 1 ? "s" : "") : "") +
                        (unitDetails?.SampleCount > 0 && unitDetails?.AdHocReportCount > 0 ? " and " : "") +
                        (unitDetails?.AdHocReportCount > 0 ? unitDetails?.AdHocReportCount + " special report " + (unitDetails?.AdHocReportCount !== 1 ? "s" : "") : "")}
                    />
                  </>
                  : <>
                    <FormControlLabel
                      value="deleteUnit"
                      control={<Radio />}
                      label="Delete the unit "
                    />
                  </>
              }

            </RadioGroup>
            <Typography mt={1}>Options:</Typography>
            <FormControlLabel
              sx={{ "& .MuiFormControlLabel-label": { color: keepContacts === true ? "#228B22" : "#BF40BF" } }}
              checked={keepContacts}
              control={<Checkbox />}
              onChange={() => setKeepContacts(!keepContacts)}
              label="Keep all contacts that are on the Unit or Components"
            />
          </FormControl>
        );
      case 1:
        return selectedOption === "deleteUnit"
          ? (handleNext())
          : (selectedOption === "moveUnit"
            ? <SearchSite
              selectedUnit={unitDetails}
              // rowSelected={(row) => setSelectedUnit(row)}
              actions={[
                {
                  action: handleSelectSiteDestination,
                  label: "Select",
                },
              ]}
            />
            : <SearchUnit
              selectedUnit={unitDetails}
              // rowSelected={(row) => handleSelectDestination(row)}
              actions={[
                {
                  action: handleSelectDestination,
                  label: "Select",
                },
              ]}
            />
          );
      case 2:
        return selectedOption === "deleteUnit" ? (
          <>
            <Typography sx={{ mb: 1 }}>
              The Unit {unitDetails?.Unit} will be DELETED
            </Typography>
            <TableContainer sx={{ mb: 1 }} component={Paper}>
              <Table
                sx={{ textAlign: "left" }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    {/* Col 1 */}
                    <TableCell>Item</TableCell>
                    {/* Col 2 */}
                    <TableCell sx={tableCol2}>Source</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Product
                    </TableCell>
                    <TableCell sx={tableCol2}>{unitDetails?.Product || ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Customer
                    </TableCell>
                    <TableCell sx={tableCol2}>{unitDetails?.Customer || ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Site
                    </TableCell>
                    <TableCell sx={tableCol2}>{unitDetails?.Site || ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Unit
                    </TableCell>
                    <TableCell sx={tableCol2}>{unitDetails?.Unit || ""}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Sample Count
                    </TableCell>
                    <TableCell sx={tableCol2}>{unitDetails?.SampleCount || 0} </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Events Count
                    </TableCell>
                    <TableCell sx={tableCol2}> {unitDetails?.EventsCount || 0} </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Notes Count
                    </TableCell>
                    <TableCell sx={tableCol2}> {unitDetails?.NotesCount || 0}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Special Reports Count
                    </TableCell>
                    <TableCell sx={tableCol2}> {unitDetails?.SpecialReportsCount || 0} </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <>
            <TableContainer sx={{ mb: 1 }} component={Paper}>
              <Table
                sx={{ textAlign: "left" }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <TableRow>
                    {/* Col 1 */}
                    <TableCell>Item</TableCell>
                    {/* Col 2 */}
                    <TableCell sx={tableCol2}>Source</TableCell>
                    {/* Col 3 */}
                    <TableCell sx={tableCol3}>Destination</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Product
                    </TableCell>
                    <TableCell sx={tableCol2}>{unitDetails?.Product || ""}</TableCell>
                    <TableCell sx={tableCol3}> {selectedUnit?.Product} </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Customer
                    </TableCell>
                    <TableCell sx={tableCol2}>{unitDetails?.Customer || ""}</TableCell>
                    <TableCell sx={tableCol3}> {selectedUnit?.Customer} </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Site
                    </TableCell>
                    <TableCell sx={tableCol2}>{unitDetails?.Site || ""}</TableCell>
                    <TableCell sx={tableCol3}>  {selectedUnit?.Site || ""}  </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Unit
                    </TableCell>
                    <TableCell sx={tableCol2}>{unitDetails?.Unit || ""}</TableCell>
                    <TableCell sx={tableCol3}>   {selectedUnit?.Unit || ""}  </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Model
                    </TableCell>
                    <TableCell sx={tableCol2}>{unitDetails?.UnitModelFormatted || ""}</TableCell>
                    <TableCell sx={tableCol3}>  {selectedUnit?.UnitModelFormatted || ""}  </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      UnitId
                    </TableCell>
                    <TableCell sx={tableCol2}>{unitDetails?.UnitId || ""}</TableCell>
                    <TableCell sx={tableCol3}>  {selectedUnit?.UnitId || ""}  </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Sample Count
                    </TableCell>
                    <TableCell sx={tableCol2}>{unitDetails?.SampleCount || 0}  </TableCell>
                    <TableCell sx={tableCol3}>     {selectedUnit?.SampleCount || 0} </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Events Count
                    </TableCell>
                    <TableCell sx={tableCol2}>  {unitDetails?.EventLogCount || 0}   </TableCell>
                    <TableCell sx={tableCol3}>   {selectedUnit?.EventsCount || 0}  </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Notes Count
                    </TableCell>
                    <TableCell sx={tableCol2}> {unitDetails?.NoteCount || 0}  </TableCell>
                    <TableCell sx={tableCol3}> {selectedUnit?.NotesCount || 0}  </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Special Reports Count
                    </TableCell>
                    <TableCell sx={tableCol2}>   {unitDetails?.SpecialReportsCount || 0}  </TableCell>
                    <TableCell sx={tableCol3}>  {selectedUnit?.SpecialReportsCount || 0}   </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        );
      default:
        return "Unknown step";
    }
  }
  return (
    <Card
      sx={{
        padding: "10px",
        minWidth: "70vw",
        minHeight: "25vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Stepper
        nonLinear={selectedOption === "deleteUnit" ? true : false}
        activeStep={activeStep}
        sx={{ mr: 2 }}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: "-20%" }}>{processResponse}</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button onClick={handleReset} sx={{ ml: "auto", mr: 1 }}>
              Close
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Typography className={ClassNames.instructions} sx={{ mt: 4 }}>
            {getStepContent(activeStep)}
          </Typography>
          <Box
            sx={{
              marginTop: "auto",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </Button>
            {activeStep !== 1
              ? <Button
                onClick={handleNext}
                disabled={activeStep === 1 && !selectedUnit?.Customer}
              >
                {activeStep === steps.length - 1 ? "Finish" : "Next"}
              </Button>
              : <></>}
          </Box>
        </>
      )}
    </Card>
  );
}
